import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
// import { Link } from 'gatsby';
// import Section from '../Testimonials/testimonials.styled';
import {
  Testimonial,
  testimonialFirstSection,
} from '../../utils/contentProvider';
// import { btnOnClick } from '../../utils/contentProvider/function';

// import {
//   MixPanelEvents,
//   // MixPanelButtons,
//   MixPanelButtonContext,
//   PostHogEvents,
//   // PostHogButtons,
//   PostHogButtonContext,
// } from '../../constants';
import 'styles/components/TestimonialsSlider.scss';
const TestimonialsSlider = ({ greybg, heading }) => {
  // const [selectedTag, setSelectedTag] = useState('Agency Owners');
  const [playVideo, setplayVideo] = useState(false);

  // const filterTag = useCallback((string) => () => setSelectedTag(string), []);

  function playVideoClick(value) {
    return () => setplayVideo((state) => (state === value ? null : value));
  }
  function playVideoClose() {
    setplayVideo(false);
  }

  const testimonialSliderRef = useRef();

  useEffect(() => {
    const arrowLeft =
      '<svg width="8" height="14" viewBox="0 0 8 14" fill="none"><path d="M6.667 12.333 1.333 7l5.334-5.333" stroke="#fff" stroke-width="1.778" stroke-linecap="round" stroke-linejoin="round"/></svg>';

    const arrowRight =
      '<svg width="8" height="14" viewBox="0 0 8 14" fill="none"><path d="M1.333 12.333 6.667 7 1.333 1.667" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const testimonialSlider = document.querySelector(
        '.testimonial-slider-block',
      );

      if (!testimonialSliderRef.current && testimonialSlider) {
        testimonialSliderRef.current = tns({
          container: testimonialSlider,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: false,
          loop: true,
          speed: 500,
          controlsText: [arrowLeft, arrowRight],
          nav: false,
          navPosition: 'bottom',

          responsive: {
            0: {
              gutter: 25,
              items: 1,
              nav: true,
              controls: false,
            },

            768: {
              controls: true,
              items: 2,
              nav: false,
            },
            1000: {
              items: 3,
            },
          },
        });
      }
    });
  }, []);

  return (
    <>
      <section
        className={cn([
          'testimonials-slider-section overflow-hidden',
          { greybg },
        ])}
        id="reviews">
        <div className="">
          <div className="head">
            <h3>
              {heading || 'Customers love ruttl for a reason'}
              &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 382.95 335.55"
                width="24px"
                height="24px">
                <g data-name="Layer 2">
                  <path
                    d="M383 100.58v8.25a24.48 24.48 0 0 0-.81 3.62 97.53 97.53 0 0 1-28.27 62.82q-76.53 78.15-153.46 155.9c-5.85 5.94-12.38 5.81-18.34-.18-5.64-5.66-11.21-11.39-16.82-17.08Q97.95 245.6 30.68 177.26C4 150.05-5.38 117.54 2.93 80.29 12.81 35.92 48.87 4.19 94 .48c26.27-2.16 50.22 5 70.64 22 8.5 7.08 16.08 15.3 23.94 23.13 2.07 2.06 3.43 2.06 5.45 0 6.23-6.47 12.33-13.12 19-19.07 25.47-22.61 55.23-31.18 88.45-24 42.22 9.18 67.17 36.79 78.1 77.86 1.82 6.53 2.3 13.44 3.42 20.18Z"
                    fill="#fb4130"
                    data-name="Layer 1"
                  />
                </g>
              </svg>
            </h3>
          </div>
          <div className="container">
            <div className="equal-height align-items-center slider-overflow-visible">
              <div className="testimonial-slider-block ">
                {testimonialFirstSection.map(
                  ({
                    id,
                    content,
                    img,
                    author,
                    designation,
                    videoAuthor,
                    videoAuthorDesignation,
                    videoAuthorImg,
                    videoPreview,
                    // videoTitle,
                  }) => (
                    <>
                      <div className="item" id={id}>
                        <div className="">
                          <div className="single-testimonial ">
                            <p className="testimonial">{content}</p>
                            <div className="testimonial-foot">
                              <div className="flex justify-content-between">
                                <div className="author">
                                  <div className="author-img">
                                    <img
                                      loading="lazy"
                                      src={img}
                                      alt={author}
                                      title={author}
                                      width="48"
                                      height="48"
                                    />
                                  </div>
                                  <div className="author-text">
                                    <p className="author-name h4">{author}</p>
                                    <p className="author-designation">
                                      {designation}
                                    </p>
                                  </div>
                                </div>
                                <div className="app-sumo-rating">
                                  <img
                                    loading="lazy"
                                    src="/assets/img/stars.svg"
                                    alt="5 star icons in a row"
                                    title="5 star icons in a row"
                                    width="93"
                                    height="16"
                                    style={{ maxWidth: '73px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div
                          className="wrapper-video"
                          style={{ width: '100%' }}>
                          <div className="single-testimonial slider-video">
                            <div
                              className={`video-overlay-div hidden-mobile ${
                                playVideo === id ? 'hidden' : ''
                              }`}>
                              <button
                                className="video-play-btn"
                                onClick={playVideoClick(id)}
                                type="button"
                                aria-label="Play Video">
                                <svg
                                  width="14"
                                  height="16"
                                  viewBox="0 0 14 16"
                                  fill="none">
                                  <path
                                    d="M3.742.624C2.411-.223.67.734.67 2.312v11.38c0 1.578 1.742 2.534 3.073 1.687l8.942-5.69a2 2 0 0 0 0-3.375L3.742.624Z"
                                    fill="#150546"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="testimonial-image">
                              <video
                                src={videoPreview}
                                loop
                                autoPlay
                                muted
                                playsInline></video>
                              {/* <iframe
                              src={
                                playVideo === id
                                  ? `${videoUrl}?autoplay=1&rel=0`
                                  : `${videoUrl}?start=15&autoplay=1&mute=1&playsinline=1`
                              }
                              frameBorder="0"
                              title={videoTitle}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay"
                              allowFullScreen></iframe> */}
                            </div>
                            <div
                              className={`testimonial-foot ${
                                playVideo === id ? 'hidden' : ''
                              }`}>
                              <div className="flex justify-content-between">
                                <div className="author">
                                  <div className="author-img">
                                    <img
                                      loading="lazy"
                                      src={videoAuthorImg}
                                      alt={videoAuthor}
                                      title={videoAuthor}
                                      width="48"
                                      height="48"
                                    />
                                  </div>
                                  <div className="author-text">
                                    <p className="author-name h4">
                                      {videoAuthor}
                                    </p>
                                    <p className="author-designation">
                                      {videoAuthorDesignation}
                                    </p>
                                  </div>
                                </div>
                                <div className="app-sumo-rating">
                                  <img
                                    loading="lazy"
                                    src="/assets/img/stars.svg"
                                    alt="5 star icons in a row"
                                    title="5 star icons in a row"
                                    width="93"
                                    height="16"
                                    style={{ maxWidth: '73px' }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                )}
                {Testimonial.map(
                  ({ content, img, author, designation, id }) => (
                    <div className="item">
                      <div key={id} className=" ">
                        <div className="single-testimonial">
                          <p className="testimonial">{content}</p>
                          <div className="testimonial-foot">
                            <div className="flex justify-content-between">
                              <div className="author">
                                <div className="author-img">
                                  <img
                                    loading="lazy"
                                    src={img}
                                    alt={author}
                                    title={author}
                                    width="40"
                                    height="40"
                                  />
                                </div>
                                <div className="author-text">
                                  <p className="author-name h4">{author}</p>
                                  <p className="author-designation">
                                    {designation}
                                  </p>
                                </div>
                              </div>
                              <div className="app-sumo-rating">
                                <img
                                  loading="lazy"
                                  src="/assets/img/app-sumo-rating.png"
                                  alt="5 appsumo tacos in a row "
                                  title="5 appsumo tacos in a row "
                                  width="73"
                                  height="8"
                                  style={{ maxWidth: '73px' }}
                                />
                                <img
                                  loading="lazy"
                                  src="/assets/img/app-sumo-full.png"
                                  alt="appsumo logo"
                                  title="appsumo logo"
                                  width="47"
                                  height="10"
                                  style={{ maxWidth: '47px' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={`video-popup-main ${playVideo ? 'show' : ''}`}>
        <div className="video-popup-wrapper">
          <button
            onClick={playVideoClose}
            className="close-popup"
            type="button">
            X
          </button>
          {testimonialFirstSection.map(({ id, videoUrl }) => (
            <div key={id}>
              {playVideo === id ? (
                <iframe
                  src={videoUrl}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

TestimonialsSlider.propTypes = {
  greybg: PropTypes.bool,
  heading: PropTypes.string,
};
TestimonialsSlider.defaultProps = {
  greybg: false,
};

export default TestimonialsSlider;
