import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
// import GetStarted from 'components/GetStarted';
import GetStartedCenter from 'components/GetStartedCenter';
import TestimonialsSlider from 'components/TestimonialsSlider/TestimonialsSlider';

// import Faq from 'components/Faq';
// import { Main } from 'styles/competitors';

import { signUp, loginWithGoogle } from '../utils/contentProvider/function';
import 'styles/competitors.scss';

const Alternatives = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Best Alternative Tool to Pastel, Usersnap, MarkUp | Ruttl 💪"
          url="https://ruttl.com/alternatives/"
          description="Try the best alternative tool to Pastel, Usersnap, MarkUp for website feedback. Ruttl helps you comment on websites, make real time edits for collaboration."
        />
        <main className="competitors-styled-main">
          <section className="competitor-hero competitor-landing-section">
            <div className="container flex align-items-center competitors-page-margin">
              <div className="competitors-page-desc">
                <div>
                  <h1>ruttl is not an alternative</h1>
                  <p>
                    ruttl is the #1 website feedback tool that simplifies live
                    website review and visual feedback for web projects.
                  </p>
                </div>
                <div
                  className="reveal-1 button-container"
                  style={{ marginBottom: 60 }}>
                  <button
                    type="button"
                    className="button "
                    onClick={loginWithGoogle}
                    style={{ padding: '7px 25px', marginLeft: '0px' }}>
                    <span>
                      <svg
                        width="26px"
                        height="26px"
                        viewBox="0 0 533.5 544.3"
                        className="button-no-translate"
                        style={{
                          verticalAlign: 'middle',
                        }}>
                        <path
                          d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                          fill="#4285f4"></path>
                        <path
                          d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                          fill="#34a853"></path>
                        <path
                          d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                          fill="#fbbc04"></path>
                        <path
                          d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                          fill="#ea4335"></path>
                      </svg>
                    </span>
                    Get Started For Free
                  </button>
                  <div>
                    <Link
                      className="button button-white"
                      style={{ padding: '16px 20px' }}
                      to="/pricing/">
                      View pricing
                      <svg
                        width="20"
                        height="20"
                        style={{ marginTop: 0 }}
                        viewBox="0 0 512 512">
                        <path
                          fill="none"
                          stroke="#160647"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M268 112l144 144-144 144M392 256H100"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="competitors-banner-container">
                <img
                  className="ruttl-competitors-banner"
                  loading="lazy"
                  src="/assets/img/ruttl-competitors-banner.svg"
                  alt="Ruttl competitors banner"
                  title="Ruttl competitors banner"
                />
              </div>
            </div>

            <section className="competitor-details">
              <div className="container">
                <div className="competetior-table-main competitive-section">
                  <h2 className="center">Competitive Landscape</h2>

                  <div className="competitor-feature-block flex">
                    <div className="feature-list">
                      <div className="feature-head flex align-items-center justify-content-center">
                        <h2>Key Features</h2>
                      </div>
                      {[
                        {
                          id: 0,
                          featuretitle: 'Review websites',
                        },
                        {
                          id: 1,
                          featuretitle: 'Textual comments',
                        },
                        {
                          id: 2,
                          featuretitle: 'Video comments',
                        },
                        {
                          id: 3,
                          featuretitle: 'Edit Content',
                        },
                        {
                          id: 4,
                          featuretitle: 'Replace Assets',
                        },
                        {
                          id: 5,
                          featuretitle: 'Real Time changes',
                        },
                      ].map(({ featuretitle, id }) => (
                        <div
                          key={id}
                          className="feature-block flex align-items-center justify-content-center">
                          <h3>{featuretitle}</h3>
                        </div>
                      ))}
                    </div>
                    <div className="feature-availability flex">
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/ruttl-logo.svg"
                            alt="ruttl-logo"
                            title="ruttl-logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check',
                          },
                          {
                            id: 3,
                            featurecheck: 'check',
                          },
                          {
                            id: 4,
                            featurecheck: 'check',
                          },
                          {
                            id: 5,
                            featurecheck: 'check',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/pastel.svg"
                            alt="pastel logo"
                            title="pastel logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 3,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 4,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 5,
                            featurecheck: 'check cross',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/usersnap.png"
                            alt="usersnap logo"
                            title="usersnap logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check',
                          },
                          {
                            id: 3,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 4,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 5,
                            featurecheck: 'check cross',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/userback.svg"
                            alt="userback logo"
                            title="userback logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check',
                          },
                          {
                            id: 3,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 4,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 5,
                            featurecheck: 'check cross',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/markup.svg"
                            alt="markup logo"
                            title="markup logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check',
                          },
                          {
                            id: 3,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 4,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 5,
                            featurecheck: 'check cross',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/marker.svg"
                            alt="marker logo"
                            title="marker logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 3,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 4,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 5,
                            featurecheck: 'check cross',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="single-feature-row">
                        <div className="feature-head flex align-items-center justify-content-center">
                          <img
                            src="/assets/img/bugherd.svg"
                            alt="bugherd logo"
                            title="bugherd logo"
                          />
                        </div>
                        {[
                          {
                            id: 0,
                            featurecheck: 'check',
                          },
                          {
                            id: 1,
                            featurecheck: 'check',
                          },
                          {
                            id: 2,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 3,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 4,
                            featurecheck: 'check cross',
                          },
                          {
                            id: 5,
                            featurecheck: 'check cross',
                          },
                        ].map(({ featurecheck, id }) => (
                          <div
                            key={id}
                            className="feature-block flex align-items-center justify-content-center">
                            <span
                              className={`flex justify-content-center align-items-center ${featurecheck}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="center upper-edge">ruttl has an upper edge</h2>
                <div className="flex flex-wrap">
                  {[
                    {
                      id: 0,
                      img: '/assets/img/usersnap.png',
                      title: 'Alternative to Usersnap',
                      pageLink: '/usersnap-alternative/',
                      description:
                        'ruttl has an upper edge as compared to Usersnap. Move over technical stuff, because ruttl can be used by everyone and not just developers.',
                    },
                    {
                      id: 1,
                      img: '/assets/img/userback.svg',
                      title: 'Alternative to Userback',
                      pageLink: '/userback-alternative/',
                      description:
                        'ruttl is better than Userback. No more tinkering with codes or widgets. Open ruttl directly on your browser, edit and review websites - it’s that simple.',
                    },
                    {
                      id: 2,
                      img: '/assets/img/pastel.png',
                      title: 'Alternative to Pastel',
                      pageLink: '/pastel-alternative/',
                      description:
                        'ruttl is one step ahead than Pastel. Don’t just comment. Edit live websites, explore multiple font properties like size, spacing, colour and more!',
                    },
                    {
                      id: 3,
                      img: '/assets/img/markup.svg',
                      title: 'Alternative to MarkUp.io',
                      pageLink: '/markup-io-alternative/',
                      description:
                        'ruttl is advantageous as compared to MarkUp. Upgrade from plain commenting to live editing, and collaborate with your team in a better manner.',
                    },
                    {
                      id: 4,
                      img: '/assets/img/bugherd.svg',
                      title: 'Alternative to BugHerd',
                      pageLink: '/bugherd-alternative/',
                      description:
                        'ruttl surpasses BugHerd in simplicity. No need to install any extensions, just use ruttl right from your browser URL to edit and review live websites.',
                    },
                    {
                      id: 5,
                      img: '/assets/img/marker.svg',
                      title: 'Alternative to Marker',
                      pageLink: '/marker-io-alternative/',
                      description:
                        'ruttl is recommended over Marker. No more codes, widgets, Chrome extensions or lengthy setups. Just copy your URL and paste it in ruttl to review.',
                    },
                  ].map(({ img, title, description, id, pageLink }) => (
                    <div key={id} className="half-col">
                      <Link to={pageLink} className="competitor-detail-single">
                        <img src={img} alt={title} title={title} />

                        <h3>{title}</h3>
                        <p>{description}</p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <div className="container">
              <div className="flex flex-wrap">
                <div className="flex-1">
                  <div className="single-hero-feature center">
                    <img
                      loading="lazy"
                      src="/assets/img/stay-organised.png"
                      alt="comment on live website using ruttl feature illustration "
                      title="comment on live website using ruttl feature illustration "
                    />
                    <h2>Comment on Live Websites</h2>
                    <p>
                      Easily share feedback with your team by leaving comments
                      on a live website. Get the changes done in no time by
                      providing specific inputs.
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="single-hero-feature center">
                    <img
                      loading="lazy"
                      src="/assets/img/faster-design-review.png"
                      alt="Make real time edits using ruttl illustration"
                      title="Make real time edits using ruttl illustration"
                    />
                    <h2>Make real time edits</h2>
                    <p>
                      Get precise edits done for font, spacing, alignment and
                      various elements even on a live website by sharing exact
                      changes with the developer.
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="single-hero-feature center">
                    <img
                      loading="lazy"
                      src="/assets/img/better-collaboration.png"
                      alt="Share ruttl project with team members illustration"
                      title="Share ruttl project with team members illustration"
                    />
                    <h2>Share with the team</h2>
                    <p>
                      Make your collaborations seamless by inviting your team,
                      notify them in real-time by instantly tagging them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <GetStartedCenter toggleSignup={toggleSignup} />

          <section className="demo-video">
            <div className="container">
              <h2 className="center">Check the demo video</h2>
              <iframe
                src="https://www.youtube.com/embed/vkx366sknD4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="center">
                <button
                  type="button"
                  onClick={() => signUp(toggleSignup)}
                  className="button"
                  style={{ minWidth: '200px', marginTop: '50px' }}>
                  Start using ruttl now
                </button>
              </div>
            </div>
          </section>
          <TestimonialsSlider />
          {/* <GetStarted toggleSignup={toggleSignup} /> */}
          {/* <Faq id="faq" /> */}
        </main>
      </>
    )}
  </Layout>
);

Alternatives.propTypes = {
  location: PropTypes.object,
};

export default Alternatives;
