import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import cn from 'clsx';

// import DemoModal from 'components/DemoModal';

// import Section from './GetStartedCenter.styled';
import { customerNumber } from '../../utils/contentProvider';
import { btnOnClick } from '../../utils/contentProvider/function';

import {
  MixPanelEvents,
  MixPanelButtons,
  MixPanelButtonContext,
  PostHogEvents,
  PostHogButtons,
  PostHogButtonContext,
} from '../../constants';
import 'styles/components/GetStartedCenter.scss';

const GetStartedCenter = () => {
  const [showDemoModal, setShowDemoModal] = useState(false);

  // function openDemoModal() {
  //   setShowDemoModal(true);
  // }

  function closeDemoModal() {
    setShowDemoModal(false);
  }

  function clickHandler(e) {
    if (e.target.id === 'footer-demo-modal') setShowDemoModal(false);
  }

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  return (
    <section className="get-started-section">
      <div className="container">
        <div className="flex flex-wrap align-items-center">
          <div className="index-get-started-text reveal-1 center-get-started">
            <h2>
              {customerNumber}
              + companies are using ruttl to save time and money on their
              projects
              <img
                loading="lazy"
                src="/assets/img/people-faces.png"
                alt="stock images of people in a row"
                title="stock images of people in a row"
                width="150"
                height="47"
              />
            </h2>
            <div className="button-container">
              <div className="get-started-buttons">
                <a
                  type="button"
                  className="button"
                  href="https://web.ruttl.com/projects"
                  target="_blank"
                  rel="noopener noreferrer">
                  Get started for free
                </a>
              </div>
              <div className="get-started-buttons">
                <button
                  type="button"
                  className="button "
                  onClick={btnOnClick({
                    mixPanelEvent: MixPanelEvents.ButtonClick,
                    to: 'https://web.ruttl.com/share/demo/',
                    config: {
                      Button: MixPanelButtons.TryDemo,
                      Context: MixPanelButtonContext.TryDemoBot,
                    },
                    posthogEvent: PostHogEvents.ButtonClick,
                    posthogConfig: {
                      Button: PostHogButtons.TryDemo,
                      Context: PostHogButtonContext.TryDemoBot,
                    },
                  })}>
                  Try demo
                  <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="footer-demo-modal"
        className={cn(['demo-modal', { show: showDemoModal === 1 }])}>
        <button
          type="button"
          className="button-unstyled"
          onClick={closeDemoModal}>
          <img
            loading="lazy"
            src="/assets/img/close.svg"
            alt="close"
            title="close"
          />
        </button>
        {showDemoModal && (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/vkx366sknD4"
            frameBorder="0"
            title="ruttl demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </section>
  );
};

export default GetStartedCenter;
